.navbar-container {
  height: 64px;
  
  padding: 13px 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .fastcash-logo {
    background: white !important;
    width: 100%;
  }
  .right-section {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    column-gap: 8px;
    padding: 5px 0px;
    .navbar-item {
      color: #000;
      font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      height: 28px;
      cursor: pointer;
      padding: 4px 8px;
      line-height: 20px; /* 142.857% */
      
    }
  }
}
